import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  Paragraph,
  PageWithSubNav,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="June 2023" subnav="release-notes">
      <div id="June2023" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          <q cite="https://www.goodreads.com/quotes/8499900-creativity-is-a-wild-mind-and-a-disciplined-eye">
            Creativity is a wild mind and a disciplined eye.
          </q>{' '}
          <br />
          <span className="uni-margin--half--left uni-text--small uni-color--subtle">
            - Dorothy Parker
          </span>
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Web"
              packageName="@hudl/uniform-web"
              packageLink="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform-web/README.md"
              changelogUrl="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform-web/CHANGELOG.md#556"
              storybookUrl="https://uniform-web.storybook.hudltools.com"
              versionedStorybookUrl="https://63dd878b6e1bac956cef23ec-qqaoofnloi.chromatic.com"
              version="5.5.6"
            />
            <div className={styles.changesList}>
              <Added>
                <code>QaIdProps</code> TypeScript interface for use in{' '}
                <code>qaId</code> prop in Uniform components.
              </Added>
              <Added>
                New{' '}
                <Link
                  href="https://uniform-web.storybook.hudltools.com/?path=/story/components-onboarding--demo"
                  isExternal>
                  Onboarding
                </Link>{' '}
                popover component.
              </Added>
              <Improved>Vite build config for better minification.</Improved>
              <Fixed>
                <Link
                  // eslint-disable-next-line max-len
                  href="https://uniform-web.storybook.hudltools.com/?path=/story/components-notifications-notice--demo&args=isTight:false"
                  isExternal>
                  Notice
                </Link>{' '}
                button layout fixed when <code>isTight</code> is set to{' '}
                <code>false</code>.
              </Fixed>
              <Fixed>
                Use accessible color for placeholder text in form elements.
              </Fixed>
              <Fixed>
                Some miscellaneous issues with{' '}
                <Link
                  href="https://uniform-web.storybook.hudltools.com/?path=/story/components-tooltip--demo"
                  isExternal>
                  Tooltips
                </Link>
                .
              </Fixed>
              <Fixed>
                <Link
                  href="https://uniform-web.storybook.hudltools.com/?path=/story/components-avatar-group--demo"
                  isExternal>
                  Avatar
                </Link>{' '}
                components that weren't properly setting their
                secondary/background colors.
              </Fixed>
              <Fixed>
                Keyboard navigation in{' '}
                <Link
                  href="https://uniform-web.storybook.hudltools.com/?path=/story/components-avatar-group--demo"
                  isExternal>
                  Avatars
                </Link>{' '}
                and{' '}
                <Link
                  href="https://uniform-web.storybook.hudltools.com/?path=/story/components-tooltip--demo"
                  isExternal>
                  Cards
                </Link>{' '}
                (accessibility).
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Modules"
              packageName="@hudl/uniform-modules"
              packageLink="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform-modules/README.md"
              changelogUrl="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform-modules/CHANGELOG.md#513"
              version="5.1.3"
            />
            <div className={styles.changesList}>
              <Added>
                <code>QaIdProps</code> TypeScript interface for use in{' '}
                <code>qaId</code> prop in Uniform components.
              </Added>
              <Fixed>
                The{' '}
                <Link href="/components/modules/data-card/design">
                  Data Card
                </Link>{' '}
                and <Link href="/components/modules/toggle/design">Toggle</Link>{' '}
                wrap content when it overflows off the card.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              packageName="@hudl/rn-uniform"
              packageLink="https://github.com/hudl/rn-uniform/tree/main/packages/rn-uniform/README.md"
              changelogUrl="https://github.com/hudl/rn-uniform/tree/main/packages/rn-uniform/CHANGELOG.md#4103"
            />
            <div className={styles.changesList}>
              <Added>
                <Link
                  // eslint-disable-next-line max-len
                  href="https://github.com/hudl/rn-uniform/blob/main/packages/rn-uniform/README.md#theme-aware-uniform-components"
                  isExternal>
                  <code>UniformThemeProvider</code>
                </Link>{' '}
                API, which replaces the <code>EnvironmentProvider</code> and
                allows for overriding Uniform styles using the{' '}
                <code>useTeamColorTheme</code> hook.
              </Added>
              <Fixed>
                Ensure all <code>IconProps</code> supplied to Uniform Icon
                components are propagated to underlying <code>Icon</code>{' '}
                component.
              </Fixed>
              <Fixed>
                Issue with{' '}
                <Link href="/components/notifications/toast/design">
                  Toast Messenger
                </Link>{' '}
                that caused unintended delays in rendering a Toast.
              </Fixed>
              <Fixed>
                Use accessible color for placeholder text in form elements.
              </Fixed>
              <Removed>
                The existing <code>Theme</code> type as it was deprecated and
                easily confused with theming types.
              </Removed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma" />
            <div className={styles.changesList}>
              <Improved>
                The <Link href="/components/spinner/design">Spinner</Link>{' '}
                component in Figma correctly animates without throwing
                conflicting property values.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
